import React, { useState } from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook, FaYoutube} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/muhammadwajahat.png'
import { Link } from 'react-scroll';
import { BsYoutube } from 'react-icons/bs';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => { 
    setNav (!nav) 
  }
  return(
      <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
          <div >
              <img className='cursor-pointer' src={Logo} alt="Logo Image" style={{width: '125px'}}/>
          </div>

          {/* menu */}
         
        <ul className='hidden md:flex '>
            <li>
                <Link to="home" smooth={true} duration={500}>Home</Link>
            </li>
            <li>
                <Link to="about" smooth={true} duration={500}>About</Link>
            </li>
            <li>
                <Link to="skills" smooth={true} duration={500}>Skills</Link>
            </li>
            <li>
                <Link to="portfolio" smooth={true} duration={500}>Portfolio</Link>
            </li>
            <li>
                <Link to="contact" smooth={true} duration={500}>Contact</Link>
            </li>
        </ul>
      

          {/* Hamburger */}

          <div onClick={handleClick} className='md:hidden z-10 text-4xl cursor-pointer'>
          {!nav ? <FaBars /> : <FaTimes />}
          </div>

         { /* Mobile menu */}

          <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl '>
                <Link onClick={handleClick} to="home" smooth={true} duration={500}>Home</Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="about" smooth={true} duration={500}>About</Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="skills" smooth={true} duration={500}>Skills</Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="portfolio" smooth={true} duration={500}>Portfolio</Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="contact" smooth={true} duration={500}>Contact</Link>
            </li>
          </ul>

          {/* Social Icons */}

          <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[50px] flex justify-between items-center ml-[-100px] hover:ml-[10px] duration-300 bg-blue-600'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/muhammad-wajahat-tareen-b6898311b/" target='_blank'>
                 Linkedin <FaLinkedin size={30} />
                 </a>
                </li>

                <li className='w-[160px] h-[50px] flex justify-between items-center ml-[-100px] hover:ml-[10px] duration-300 bg-[#333333]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://github.com/mohammadwajahat" target='_blank'>
                 Github <FaGithub size={30} />
                 </a>
                </li>

                <li className='w-[160px] h-[50px] flex justify-between items-center ml-[-100px] hover:ml-[10px] duration-300 bg-[#CD201F]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://www.youtube.com/@codewithwajahat" target='_blank'>
                 YouTube <BsYoutube size={30} />
                 </a>
                </li>

                <li className='w-[160px] h-[50px] flex justify-between items-center ml-[-100px] hover:ml-[10px] duration-300 bg-[#565f69]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="/">
                 Resume <BsFillPersonLinesFill size={30} />
                 </a>
                </li>
               { /*<li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[10px] duration-300 bg-[#FF0000]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="/">
                 YouTube <FaYoutube size={30} />
                 </a>
              </li> */}
                
            </ul>
          </div>
          
      </div>
  )
}

export default Navbar;
import React from 'react';

const Footer =()=>{
  return(
    <div className='text-1xl w-full text-[#0a192f] text-1xl h-10 text-center flex-auto  py-2 bg-yellow-400 shadow-lg shadow-white text-bold'>
      <p><span className='font-bold  cursor-pointer'>Muhammad Wajahat ©</span>Copyright 2024 All right reserved </p>
    </div>
  )
}

export default Footer;
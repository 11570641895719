import About from "./compenents/About";
import Contact from "./compenents/Contact";
import Home from "./compenents/Home";
import Navbar from "./compenents/Navbar";
import Skills from "./compenents/Skills";
import Portfolio from "./compenents/Portfolio";
import Footer from "./compenents/Footer";
import WhatsApp from './assets/whatsapp.png';
import Slides from "./Json/Mock.json";


function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Portfolio />
      <Contact />
      <Footer />
      <div class="fixed bottom-8 pl-5">
      <a href="https://wa.me/+923094872801?text=Hello How can I help you?" traget="_blank">
      <img src={WhatsApp} width="40" alt="whatsapp" />
      </a>
      
      </div>
    </div>
  );
}

export default App;
